import { triggerSnackbar, triggerBanner, clearSnackbar } from '@wf-mfe/notification-service'

export const _alertsContext = {
  addBanner: (phoenixBannerArgs) => {
    const { type = 'info', content, onPrimaryClick, onSecondaryClick, primaryActionText, secondaryActionText, trigger } = phoenixBannerArgs
    triggerBanner({
      type,
      message: content,
      primaryAction: onPrimaryClick,
      primaryActionText,
      secondaryAction: onSecondaryClick,
      secondaryActionText,
      trigger,
    })

  },
  addSnackbar: (phoenixSnackbarArgs) => {
    const { actionText, content, onClick, trigger, key, duration } = phoenixSnackbarArgs
    return triggerSnackbar({
      key,
      message: content,
      action: onClick,
      actionText,
      trigger,
      timeout: duration
    })
  },
  closeSnackbar: (key) => {
    clearSnackbar ? clearSnackbar(key) : console.log('closeSnackbar isn\'t implemented yet. Please use then fn returned from `addSnackbar` instead')
  }
}

import React from 'react'
import alertsContext from './alertsContext.js'
import { _alertsContext } from './internal/__alertsContext.js'

export default function AlertsManager ({children}) {
  return (
    <alertsContext.Provider value={_alertsContext}>
      {children}
    </alertsContext.Provider>
  )
}